<template>
  <button class="top-btn" @click="scrollToTop()">
    <img src="https://img.davich.com/image/btn-top.png" alt="top button icon" />
  </button>
</template>

<script>
export default {
  methods: {
    scrollToTop() {
      const self = this;
      self.$emit("initNum");
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    topBtnShow() {
      let topBtn = document.querySelector(".top-btn");
      if (window.pageYOffset >= 100) {
        topBtn.classList.add("show");
      } else {
        topBtn.classList.remove("show");
      }
    },
  },
  mounted() {
    document.addEventListener("scroll", this.topBtnShow);
  },

  destroyed() {
    document.removeEventListener("scroll", this.topBtnShow);
  },
};
</script>

<style lang="scss" scoped>
.top-btn {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #003a70;
  position: fixed;
  z-index: 99999;
  padding-top: 8px;
  opacity: 0;
  transform: translateY(-50%);
  transition: 0.3s;
  bottom: 90px;
  right: 20px;
  &.show {
    opacity: 1;
    transform: translateY(0);
  }
  img {
    width: 24px;
    height: 25px;
  }
}
</style>
