<template>
  <button class="top-btn" @click="scrollToTop()">
    <img
      src="https://img.davich.com/mobile/image/btn-top.png"
      alt="top button icon"
    />
  </button>
</template>

<script>
import { scrollIntoView } from "seamless-scroll-polyfill";

export default {
  methods: {
    scrollToTop() {
      scrollIntoView(document.querySelector("#app"), {
        block: "start",
        behavior: "smooth",
      });
    },
    topBtnShow() {
      let topBtn = document.querySelector(".top-btn");
      if (window.pageYOffset >= 100) {
        topBtn.classList.add("show");
      } else {
        topBtn.classList.remove("show");
      }
    },
  },
  mounted() {
    document.addEventListener("scroll", this.topBtnShow);
  },

  destroyed() {
    document.removeEventListener("scroll", this.topBtnShow);
  },
};
</script>

<style lang="scss" scoped>
.top-btn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #003a70;
  position: fixed;
  bottom: 68px !important;
  right: 20px;
  z-index: 99999;
  padding-top: 8px;
  opacity: 0;
  transform: translateY(-50%);
  transition: 0.3s;
  &.show {
    opacity: 1;
    transform: translateY(0);
  }
  img {
    width: 19px;
    height: 20px;
  }
}
</style>
