import Vue from "vue";
import App from "./App.vue";

import router from "./router/index.js";
import { store } from "./store/index.js";
import scss from "./assets/scss/style.scss";

import VModal from "vue-js-modal";
import BootstrapVue from "bootstrap-vue";
import VueMeta from 'vue-meta';
// import "bootstrap/dist/css/bootstrap.css";
// import "bootstrap-vue/dist/bootstrap-vue.css";

// Vue.config.productionTip = process.env.NODE_ENV === "production";

Vue.use(BootstrapVue);
Vue.use(VModal);

Vue.use(VueMeta, {
  keyName: 'metaInfo',
  attribute: 'data-vue-meta',
  ssrAttribute: 'data-vue-meta-server-rendered',
  tagIDKeyName: 'vmid',
  refreshOnceOnNavigation: true
});

new Vue({
  render: (h) => h(App),
  router: router,
  store: store,
  scss: scss,
}).$mount("#app");
