<template>
    <div>
      <sub-title :title="title" :content="content"> </sub-title>
      <section class="magazine scroll-active">
        <h2 class="hidden">매거진</h2>
        <!-- <p class="magazine-title"> 「도록」 은 다비치안경의 ‘인사이트(in-site/insight)’ 매거진입니다.</p>
        <p class="magazine-content">
            본사와 가맹점 등 다비치의 모든 현장을 현업으로 삼고 있는(in-site)분들을 위해<br/>
            일과 삶을 아우르는 다양한 통찰(insight)이 되는 소식지 이자 매개가 되고자 합니다.<br/><br/>
            다비치가 다비치를, 다비치가 사람들을, 다비치가 세상을 더 선명히 조망하-도록,<br/>
            다비치 인들의 눈과 마음에 담겨 온, 지금 담긴, 앞으로 담아갈 비전을 담았습니다.
        </p> -->
        <div class="magazine-swtitle">contents</div>

        <div class="swiper-container">
          <swiper class="swiper-wrapper magazine-item" ref="filterSwiper" :options="swiperOption">
              <swiper-slide v-for="item in previewList" :key="item.id" class="magazine-box"><router-link :to="`/whatsnew/magazineDetail/${item.id}`"><p :class="(item.cnt % 2 != 0 ?'magazine-item1':'')"><img :src="item.imgSrc[0]"></p><span v-html="item.under_title"></span></router-link></swiper-slide>
          </swiper>
        </div>
      </section>
    </div>
  </template>
  
  <script>
  import SubTitle from "../../components/SubTitle1.vue";
  import { mapGetters, mapState } from "vuex";
  import "swiper/css/swiper.css";
  import {Swiper, SwiperSlide, directive } from 'vue-awesome-swiper'

  export default {
    beforeCreate() {
      this.$store.dispatch("whatsnew/FETCH_MAGAZINE");
    },
    name: 'FilterSwiper',
    components: {
      SubTitle,
      Swiper,
      SwiperSlide
      // TabMenu2,
    },
    directives: {
      swiper: directive
    },
    data() {
      return {
        title: "",
        content: "",
  
        currentPage: 1,
        perPage: 1,
  
        lengthListToShow: 0,
        swiperOption: {
          speed:7e3,
          freemode:true,
          slidesPerView: 7,
          spaceBetween: 2,
          loop: true,
          loopAdditionalSlides : 0,
          loopedSlides:0,
          autoplay:{
            delay:1,
            disableOnInteraction: false,
          },
        },
      };
    },
    mounted(){
        document.querySelectorAll('.swiper-wrapper')[1].style.transitionTimingFunction='linear';
    },
    computed: {
      ...mapGetters("whatsnew", ["getMagazineData"]),
      ...mapState("whatsnew", {
        magazineData: (state) => state.magazineData,
      }),

      previewList(){
        const self = this;
        const clonedData = JSON.parse(JSON.stringify(self.getMagazineData));
        if(clonedData != null && clonedData != undefined && clonedData != ''){
          const filteredData = clonedData.map(item => {
            const filteredImgSrc = item.type
              .map((type, index) => {
                if (type === 'main') {
                  return item.imgSrc[index];
                }
                return null;
              }).filter(imgSrc => imgSrc !== null);

            return {
              ...item,
              imgSrc: filteredImgSrc,
            };
          });

          return filteredData;
        }else if(!sessionStorage.getItem('reloaded')) {
          sessionStorage.setItem('reloaded', 'true');
          window.location.reload();
        }
      },
    },
  
    watch: {
      
    },
  
    methods: {
     
    },
  };
  </script>
  
  <style lang="scss" scoped>
  @import "~@/assets/scss/helpers/variables";
  @import "~@/assets/scss/helpers/mixins";
  @import "~@/assets/scss/base/typhography";
  
  .sub-title {
    background-image: url("https://img.davich.com/image/sub/subtitle/sub-what-bg-03_v2.jpg");
  }
  .magazine{
    font-family: 'Noto Sans KR,sans-serif';
    text-align: center;
  }
  .magazine-title{
    font-weight: bolder;
    padding-top: 2.2%;
    font-size: 2.1vw;
  }
  .magazine-content{
    padding-top:2.1%;
    font-size: 2.4em;
    line-height: 110%;
  }
  .magazine-swtitle{
    padding-top: 1.2%;
    font-size: 2vw;
  }
  .magazine-item p{
    text-align: center;
    position: relative;
    height:342.5px;
    width:13.8vw;
    border: 1px solid gray;
    display: inline-block;
    margin-left:7px;
    margin-right:7px;
    border-radius: 10px;
    align-content: end;
  }
  .magazine-item img{
    object-fit: fill;
    width: 100%;
    height: 100%;
    border-radius: 10px;
  }
  .magazine-item span{
    line-height: 24px;
    padding-top:3%;
    padding-bottom:5%;
  }
  .magazine-item{
    height:fit-content;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -1.6%;
  }
  .magazine-item1{
    margin-top:22%;
  }
  .magazine-box{
    display: flex; align-items: center; justify-content: center; flex-direction: column;
  }
  .swiper-container{
     min-height: 520px;
     max-width:100%;
  }
  </style>