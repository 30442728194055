<template>
  <div class="info-content ly-w1400" id="info-content">
    <section class="full-page-scroll">
      <sub-title :title="title" :content="content"> </sub-title>
    </section>
    <aside class="info-sidebar sidebar">
      <button
        class="sidebar-btn sidebar-btn1"
        :class="{
          sidebarActive:
            activeSection == 1 ||
            activeSection == 2 ||
            activeSection == 3 ||
            activeSection == 4,
        }"
        v-on:click="scrollToSection(1)"
      >
        <span>경영이념</span>
      </button>
      <button
        class="sidebar-btn sidebar-btn2"
        :class="{ sidebarActive: activeSection == 5 }"
        v-on:click="scrollToSection(5)"
      >
        <span>회사연혁</span>
      </button>
    </aside>
    <!--오른쪽 버튼 -->
    <aside class="info-side-dot side-dot">
      <div
        class="dot"
        :class="{ dotActive: activeSection - 1 == index }"
        v-on:click="scrollToSection(index + 1)"
        v-for="(offset, index) in shiftedOffsets"
        :key="index"
      ></div>
    </aside>
    <!--왼쪽 버튼(dot) -->
    <section class="thought-item full-page-scroll info-section reliability">
      <div class="thought-desc">
        <div class="thought-ttl">
          <h2 class="scroll-fade-in-f">Reliability</h2>
          <p class="scroll-fade-in-f scroll-delay-1">신뢰할 수 있는 브랜드</p>
        </div>
        <p class="thought-txt">
          <span class="scroll-fade-in-f scroll-delay-2">
            보이지 않았지만 우리는 항상 지켜왔습니다. <br />
            언제나 고객이 필요할 때 있어야 할 곳에서 묵묵히 해야 할 일을
            했습니다. <br />신뢰는 보이지 않는 작은 곳에서부터 시작되는 것을
            우리는 잘 알고 있기 때문입니다. <br />
          </span>
          <span class="scroll-fade-in-f scroll-delay-3">
            보이지 않는 곳에서 손을 내밀어 도움을 주는 여러분이 믿고 의지 할 수
            있는 우리는 다비치입니다. <br />
          </span>
        </p>
      </div>
      <div class="scroll-mouse">
        <img
          src="https://img.davich.com/image/main/icon-scroll.png"
          alt="scroll icon"
        />
      </div>
    </section>
    <!--reliability-->
    <section class="thought-item full-page-scroll empathy">
      <div class="thought-desc">
        <div class="thought-ttl">
          <h2 class="scroll-fade-in-f">Empathy</h2>
          <p class="scroll-fade-in-f scroll-delay-1">함께 공감하는 브랜드</p>
        </div>
        <p class="thought-txt">
          <span class="scroll-fade-in-f scroll-delay-2">
            세상을 보는 관점을 바꾸는 것은 매우 어려운 일이었습니다. <br />
          </span>
          <span class="scroll-fade-in-f scroll-delay-3">
            노력은 항상 어려운 일에 필요합니다. <br />
            고객의 시선으로 세상을 이해하는 것은 말로는 쉽게 할 수 있습니다.
            <br />
            그 일이 어려운 일인 것을 알고 있기에 우리는 노력하고 있습니다.
            <br />
            마음으로부터 다가가기 위해 노력하고 있습니다. <br />
            진실된 경험을 전달하기 위해 노력하고 있습니다. <br />
          </span>
          <span class="scroll-fade-in-f scroll-delay-4">
            당신과 같은 곳을 바라보는 우리는 다비치입니다. <br />
          </span>
        </p>
      </div>
      <div class="scroll-mouse">
        <img
          src="https://img.davich.com/image/icon-scroll-b.png"
          alt="scroll icon"
        />
      </div>
    </section>
    <!--empathy-->
    <section class="thought-item full-page-scroll attunement">
      <div class="thought-desc">
        <div class="thought-ttl">
          <h2 class="scroll-fade-in-f">Attunement</h2>
          <p class="scroll-fade-in-f scroll-delay-1">항상 조율하는 브랜드</p>
        </div>
        <p class="thought-txt">
          <span class="scroll-fade-in-f scroll-delay-2">
            하루가 다르게 세상은 빠르게 변하고 있습니다. <br />우리를 불안하게
            하는 많은 것들이 나타났다 사라지는 일을 반복합니다. <br />불안한
            많은 일들을 마주했을 때 균형을 잡으면 한쪽으로 치우치지 않습니다.
            <br />기술이 발전하고 시대가 바뀌어도 변하지 말아야 할 것들이
            있습니다. <br />
          </span>
          <span class="scroll-fade-in-f scroll-delay-3">
            복잡한 세상의 중심에서 당신을 위해 균형을 잡고 있겠습니다. <br />
            당신과 함께하는 우리는 다비치입니다. <br />
          </span>
        </p>
      </div>
      <div class="scroll-mouse">
        <img
          src="https://img.davich.com/image/icon-scroll-b.png"
          alt="scroll icon"
        />
      </div>
    </section>
    <!--attunement-->
    <section class="thought-item full-page-scroll be-like ly-cont">
      <div class="be-like-cont">
        <div class="be-like-cont-inner ly-cont">
          <div class="be-like-item scroll-fade-in-f">
            <p>
              <strong>Reliability</strong>
              신뢰할 수 있는
            </p>
            <span>머리(의식)</span>
          </div>
          <div class="be-like-item scroll-fade-in-f scroll-delay-1">
            <p>
              <strong>Empathy</strong>
              함께 공감하는
            </p>
            <span>가슴(마음)</span>
          </div>
          <div class="be-like-item scroll-fade-in-f scroll-delay-2">
            <p>
              <strong>Attunement</strong>
              항상 조율하는
            </p>
            <span>손(표현)</span>
          </div>
          <div class="be-like-arrow scroll-fade-in-f scroll-delay-3">
            <img
              src="https://img.davich.com/image/sub/about/icon-arrow.png"
              alt="right arrow icon"
            />
          </div>
          <div
            class="be-like-item be-like-main scroll-fade-in-f scroll-delay-4"
          >
            <h2>다비치다움</h2>
          </div>
        </div>
      </div>
      <p class="thought-txt">
        <span class="scroll-fade-in-f">
          다비치다움이란 다비치의 기본 원칙입니다. <br />이것은 모두에게 변하지
          않는 신뢰를 만들어가는 과정이며, 이를 묵묵히 지켜가고자 하는
          정신입니다. <br />
        </span>
        <span class="scroll-fade-in-f scroll-delay-1">
          우리만의 전문성을 바탕으로 한 고객 응대 프로세스를 만들어 왔으며,
          <br />전국 공통의 보편적 서비스로 추구하기 위해 우리의 모든 경험을
          체계화 하기위한 노력을 지속하고 있습니다.
          <br />
        </span>
        <span class="scroll-fade-in-f scroll-delay-2">
          우리의 노력은 궁극적으로 고객을 향한 봉사정신과 활동이며, 이러한
          묵묵한 발걸음을 통해 고객에게 감동을 드릴 수 있다는 사명감을 최선을
          다해 지켜가고 있습니다. <br />
        </span>
      </p>
      <div class="scroll-mouse">
        <img
          src="https://img.davich.com/image/icon-scroll-b.png"
          alt="scroll icon"
        />
      </div>
    </section>
    <!--be like-->
    <section class="info-history full-page-scroll info-section">
      <div class="history-cont">
        <div class="history-img scroll-fade-in-f">
          <img
            src="https://img.davich.com/image/sub/about/img-history.jpg"
            alt="right arrow icon"
          />
        </div>
        <div class="history-desc scroll-fade-in-f scroll-delay-1">
          <div class="history-desc-inner">
            <p v-for="item in getHistory" :key="item.id">
              <em v-html="item.yyyy"></em
              ><span v-html="item.description"></span>
            </p>
          </div>
        </div>
      </div>
    </section>
    <!-- 연혁 -->
    <top-button v-on:initNum="initNum" class="top-btn-main pc-btn"></top-button>
  </div>
</template>

<script>
import SubTitle from "../../components/SubTitle1.vue";
import { mapGetters } from "vuex";
import TopButton from "../../components/TopButton.vue";

import { scrollIntoView } from "seamless-scroll-polyfill";

export default {
  components: {
    SubTitle,
    TopButton,
  },
  data() {
    return {
      title: "기업소개",
      content: "전문성과 신뢰를 바탕으로 한 최고의 안경전문기업, 다비치입니다.",

      inMove: false,
      activeSection: 0,
      offsets: [],
      shiftedOffsets: [],
    };
  },
  computed: {
    ...mapGetters("about", ["getHistory"]),
  },

  methods: {
    //각 섹션의 오프셋top값 저장하는 함수
    calculateSectionOffsets() {
      let section = document.getElementsByClassName("full-page-scroll");

      for (let i = 0; i < section.length; i++) {
        let sectionOffset = section[i].offsetTop;
        this.offsets.push(sectionOffset);
      }

      //dot사이드바 구현을 위해 첫번째 오프셋 제거
      for (let i = 1; i < section.length - 1; i++) {
        let sectionOffset = section[i].offsetTop;
        this.shiftedOffsets.push(sectionOffset);
      }
    },

    handleMouseWheel: function (e) {
      const history = document.querySelector(".info-history");
      //연혁관리 부분을 제외한 section에 스크롤 기능 추가
      if (history.getBoundingClientRect().top > 30) {
        if (e.deltaY > 0 && !this.inMove) {
          this.moveDown();
        } else if (e.deltaY < 0 && !this.inMove) {
          this.moveUp();
        }
        e.preventDefault();
        return false;
      }
    },

    moveDown() {
      this.inMove = true;
      this.activeSection++;
      if (this.activeSection > this.offsets.length - 1) {
        this.activeSection = this.offsets.length - 1;
      }
      this.scrollToSection(this.activeSection, true);
    },
    moveUp() {
      this.inMove = true;
      this.activeSection--;
      if (this.activeSection > 5) {
        this.activeSection = 5;
      }
      this.scrollToSection(this.activeSection, true);
    },

    //해당 컴포넌트로 스크롤
    scrollToSection(id, force = false) {
      if (this.inMove && !force) return false;

      this.activeSection = id;
      this.inMove = true;

      setTimeout(() => {
        scrollIntoView(
          document.getElementsByClassName("full-page-scroll")[id],
          {
            block: "start",
            behavior: "smooth",
          }
        );
      }, 150);

      if (this.activeSection != 0 || this.activeSection != 5) {
        setTimeout(() => {
          this.inMove = false;
        }, 151);
      }
    },

    // top button
    initNum() {
      const self = this;
      self.activeSection = 0;
    },
  },

  mounted() {
    ///////////////풀스크린 스크롤
    this.calculateSectionOffsets();

    const thisPage = document.getElementById("info-content");

    thisPage.addEventListener("wheel", this.handleMouseWheel, {
      passive: false,
    });
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";

.sub-title {
  background-image: url("https://img.davich.com/image/sub/subtitle/sub-about-bg-04.png");
}

.info-content {
  /*경영이념*/
  min-width: 1400px;
  .thought-item {
    width: 100%;
    height: 100vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    &.reliability {
      color: $color-white;
      background: center / cover no-repeat
        url("https://img.davich.com/image/sub/about/img-thought-bg-01.jpg");
      .thought-desc {
        &:before {
          background-color: $color-white;
        }
      }
    }
    &.empathy {
      background: center / cover no-repeat
        url("https://img.davich.com/image/sub/about/img-thought-bg-02.jpg");
    }
    &.attunement {
      background: center / cover no-repeat
        url("https://img.davich.com/image/sub/about/img-thought-bg-03.jpg");
    }

    .thought-desc {
      position: relative;
      &:before {
        content: "";
        position: absolute;
        top: -118px;
        left: 50%;
        transform: translateX(-50%);
        width: 2px;
        height: 70px;
        background-color: $color-black;
      }
      .thought-ttl {
        margin-bottom: 50px;
        h2 {
          line-height: normal;
          font-family: $en-font;
          font-size: 60px;
          font-weight: 700;

          letter-spacing: -1.8px;
        }
        p {
          font-size: 25px;
          letter-spacing: -0.75px;
        }
      }
    }
  }

  //경영이념 본문
  .thought-txt {
    @include font-main;
    font-weight: 300;
    span {
      display: block;
      margin-bottom: 3.7vh;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  //다비치다움
  .be-like {
    display: block;
    padding-top: 120px;
    box-sizing: border-box;
    .be-like-cont {
      margin-bottom: 8.3vh;
      .be-like-cont-inner {
        display: flex;
        justify-content: center;
        .be-like-item {
          p,
          h2 {
            width: 320px;
            height: 320px;
            border-radius: 50%;
            border: 1px solid $color-blue;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: relative;
            @include font-main;
            letter-spacing: -0.54px;
            strong {
              display: block;
              font-size: 35px;
              letter-spacing: -1.05px;
              font-weight: 700;
              color: $color-blue;
              line-height: normal;
              margin-bottom: 10.5px;
            }
          }
          h2 {
            background-color: $color-blue;
            color: $color-white;
            font-size: 35px;
            font-weight: 700;
            margin-bottom: 10.5px;
          }
          span {
            @include font-main;
            letter-spacing: -0.54px;
            margin-top: 2.7vh;
            display: inline-block;
          }
          &:nth-child(2) {
            margin: 0 -42px;
          }
        }
        .be-like-arrow {
          height: 350px;
          display: flex;
          align-items: center;
          //화살표
          img {
            width: 80px;
            height: 20px;
          }
        }
      }
    }
  }

  /* 사이드 bar */
  .info-sidebar {
    z-index: 3;
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    right: 10px;
    .sidebar-btn {
      @include font-main;
      display: block;
      width: 50px;
      height: 145px;
      border: 1px solid $color-blue;
      transform: skewY(40deg);
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $color-blue;
      background-color: $color-white;
      span {
        transform: skewY(-40deg);
        writing-mode: vertical-rl;
        text-orientation: upright;
      }
      &.sidebarActive {
        background-color: $color-blue;
        border: 1px solid $color-blue;
        color: $color-white;
      }
    }
  }

  /*사이드 dot*/
  .info-side-dot {
    position: fixed;
    top: 50%;
    transform: translateY(-50%);
    left: 48px;
    z-index: 3;
    .dot {
      margin-bottom: 25px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #777777;
      opacity: 0.5;
      &.dotActive {
        position: relative;
        opacity: 1;
        &:before {
          content: "";
          @include box-center;
          width: 20px;
          height: 20px;
          border: 1px solid #777;
          border-radius: 50%;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  /*회사연혁*/
  .info-history {
    width: 100%;
    height: 100vh;
    .history-cont {
      position: relative;
      height: 100vh;
      .history-img {
        position: absolute;
        top: 0;
        left: 0;
        width: 50vw;
        min-width: 700px;
        display: inline-block;
        height: 100vh;
        overflow: hidden;
        &.fixed {
          position: fixed;
        }
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }
      .history-desc {
        padding-left: max(55vw, 750px);
        padding-top: 100px;
        padding-bottom: 100px;
        height: 100%;
        width: 100%;
        min-width: 1400px;
        overflow-y: scroll;
        font-size: 18px;
        &::-webkit-scrollbar {
          width: 6px;
        }
        &::-webkit-scrollbar-track {
          background-color: transparent;
        }
        &::-webkit-scrollbar-thumb {
          border-radius: 3px;
          background-color: #dedede;
        }
        &::-webkit-scrollbar-button {
          width: 0;
          height: 0;
        }
        .history-desc-inner {
          p {
            padding: 17.5px 0;
            display: flex;
            em {
              font-family: $en-font;
              font-weight: 600;
              margin-right: 37px;
              line-height: 35px;
            }
            span {
              line-height: 35px;
              span {
                display: block;
                &:last-child {
                  margin-bottom: 0;
                }
              }
            }
          }
        }
      }
    }
  }
}

.top-btn-main {
  z-index: 999999999;
  .top-btn {
    z-index: 999999999;
  }
}
</style>
