/* eslint-disable no-undef */
<template>
  <div class="about-map ly-w1400">
    <sub-title :title="title" :content="content"> </sub-title>
    <main>
      <section class="davich-map ly-cont scroll-active">
        <h2 class="map-ttl">다비치 안경체인 본사</h2>
        <div class="map-api" id="bonsa"></div>
        <div class="address">
          <p>
            <em>주소</em>
            <span>서울특별시 종로구 수표로 91 다비치타워 8-11F</span>
          </p>
          <p>
            <em>전화번호</em>
            <span>070-7432-0957</span>
          </p>
        </div>
      </section>
      <!--/본사 지도-->
      <section class="davich-map ly-cont scroll-fade-in scroll-delay-1">
        <h2 class="map-ttl">고객가치경영연구원</h2>
        <div class="map-api" id="research"></div>
        <div class="address">
          <p>
            <em>주소</em>
            <span>대전광역시 동구 중동 92-1(중앙로 198)</span>
          </p>
          <p>
            <em>전화번호</em>
            <span>070-7428-6637~8</span>
          </p>
        </div>
      </section>
      <!--/고객 가치 경영 연구원 지도-->
    </main>
  </div>
</template>

<script>
/*eslint-disable */
import SubTitle from "../../components/SubTitle1.vue";

export default {
  components: {
    SubTitle,
  },
  data() {
    return {
      title: "오시는 길",
      content: "전문성과 신뢰를 바탕으로 한 최고의 안경전문기업, 다비치입니다.",
    };
  },

  mounted() {
    const bonsa = document.getElementById("bonsa");
    // const bonsaGeo = [37.5544678, 126.9673742];
    const bonsaGeo = [37.5693842953837, 126.989299276542];
    const research = document.getElementById("research");
    const researchGeo = [36.3303724, 127.4312436];

    let bonsaLocation = new naver.maps.Map(bonsa, {
      center: new naver.maps.LatLng(bonsaGeo[0], bonsaGeo[1]),
      zoom: 17,
    });

    let bansaMarkerOpt = {
      position: new naver.maps.LatLng(bonsaGeo[0], bonsaGeo[1]),
      map: bonsaLocation,
      icon: {
        content: `<div class="location-marker"><div class="marker-name" style="    position: absolute;
    top: 14px;
    left: 43px;
    z-index: 99999;
    width: 200px;
    font-size: 15px;
    font-weight: bold; letter-spacing:-0.3px;
    color: white;">다비치 안경체인 본사</div><img src="https://img.davich.com/image/sub/support/map/icon-map-name.png" alt=""  style="margin: 0px; padding: 0px; border: 0px solid transparent; display: block; max-width: none; max-height: none;  -webkit-user-select: none; position: absolute; left: 0px; top: 0px;"><div>`,
        size: new naver.maps.Size(22, 35),
        anchor: new naver.maps.Point(11, 35),
      },
    };

    new naver.maps.Marker(bansaMarkerOpt);

    let researchLocation = new naver.maps.Map(research, {
      center: new naver.maps.LatLng(researchGeo[0], researchGeo[1]),
      zoom: 17,
    });

    let researchMarkerOpt = {
      position: new naver.maps.LatLng(researchGeo[0], researchGeo[1]),
      map: researchLocation,
      icon: {
        content: `<div class="location-marker"><div class="marker-name" style="    position: absolute;
    top: 14px;
    left: 46px;
    z-index: 99999;
    width: 200px;
    font-size: 15px;
    font-weight: bold; 
    color: white;">고객가치경영연구원</div><img src="https://img.davich.com/image/sub/support/map/icon-map-name.png" alt=""  style="margin: 0px; padding: 0px; border: 0px solid transparent; display: block; max-width: none; max-height: none;  -webkit-user-select: none; position: absolute; left: 0px; top: 0px;"><div>`,
        size: new naver.maps.Size(22, 35),
        anchor: new naver.maps.Point(11, 35),
      },
    };

    new naver.maps.Marker(researchMarkerOpt);
  },
};
</script>

<style lang="scss" scoped>
@import "~@/assets/scss/helpers/variables";
@import "~@/assets/scss/helpers/mixins";
@import "~@/assets/scss/base/typhography";
.about-map {
  .sub-title {
    background-image: url("https://img.davich.com/image/sub/subtitle/sub-about-bg-04.png");
  }
  main {
    padding-top: 70px;
    .davich-map {
      margin-bottom: 150px;
      .map-ttl {
        @include font-h-35;
        margin-bottom: 40px;
      }
      .map-api {
        height: 500px;
        background-color: gray;
      }
      .address {
        margin-top: 40px;
        width: 100%;
        border-top: 1px solid $color-black;
        p {
          width: 100%;
          border-bottom: 1px solid #cccccc;
          padding: 20px 0;
          em {
            display: inline-block;
            padding: 0 45px 0 41px;
            font-weight: 500;
            width: 145px;
          }
        }
      }
    }
  }
}
</style>
